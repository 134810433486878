body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.angebot-detail .slick-list {
  height: 300px;
}

.slick-dots li button::before {
  color: #fff;
  font-size: 11px;
  margin-bottom: 5px;
}

.verkaufen-slider .slick-dots li button::before {
  color: #333;
  font-size: 11px;
  margin-bottom: 5px;
}

.verkaufen-slider .slick-next:before,
.verkaufen-slider .slick-prev:before {
  color: #333;
}

.angebot-detail .slick-dots li button::before {
  color: #333;
}

.slick-prev:before {
  content: "\2190" !important;
}

.slick-next:before {
  content: "\2192" !important;
}

.slick-dots li button:before {
  content: "\2022" !important;
}

.slick-dots {
  bottom: -75px !important;
  height: 75px;
}

.slick-dots li {
  margin: 0 0;
}

.slick-track {
  margin: auto;
}

@media only screen and (min-width: 960px) {
  .angebot-detail .slick-list {
    height: 400px;
  }

  .slick-dots li {
    margin: 0 3px;
  }
}

/* roboto-300 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url("./fonts/roboto-v30-latin-300.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./fonts/roboto-v30-latin-300.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/roboto-v30-latin-300.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./fonts/roboto-v30-latin-300.woff")
      format("woff"),
    /* Modern Browsers */ url("./fonts/roboto-v30-latin-300.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/roboto-v30-latin-300.svg#Roboto")
      format("svg"); /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/roboto-v30-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./fonts/roboto-v30-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/roboto-v30-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./fonts/roboto-v30-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */ url("./fonts/roboto-v30-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./fonts/roboto-v30-latin-regular.svg#Roboto") format("svg"); /* Legacy iOS */
}
/* roboto-500 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: url("./fonts/roboto-v30-latin-500.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./fonts/roboto-v30-latin-500.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/roboto-v30-latin-500.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./fonts/roboto-v30-latin-500.woff")
      format("woff"),
    /* Modern Browsers */ url("./fonts/roboto-v30-latin-500.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/roboto-v30-latin-500.svg#Roboto")
      format("svg"); /* Legacy iOS */
}
/* roboto-700 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("./fonts/roboto-v30-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./fonts/roboto-v30-latin-700.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/roboto-v30-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./fonts/roboto-v30-latin-700.woff")
      format("woff"),
    /* Modern Browsers */ url("./fonts/roboto-v30-latin-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/roboto-v30-latin-700.svg#Roboto")
      format("svg"); /* Legacy iOS */
}

/* poppins-regular - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/poppins-v20-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./fonts/poppins-v20-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/poppins-v20-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./fonts/poppins-v20-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */ url("./fonts/poppins-v20-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./fonts/poppins-v20-latin-regular.svg#Poppins") format("svg"); /* Legacy iOS */
}
/* poppins-600 - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  src: url("./fonts/poppins-v20-latin-600.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./fonts/poppins-v20-latin-600.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/poppins-v20-latin-600.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./fonts/poppins-v20-latin-600.woff")
      format("woff"),
    /* Modern Browsers */ url("./fonts/poppins-v20-latin-600.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/poppins-v20-latin-600.svg#Poppins")
      format("svg"); /* Legacy iOS */
}

/* Material Icons */
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/MaterialIcons.woff2") format("woff2");
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

/* shadows-into-light-regular - latin */
@font-face {
  font-family: "Shadows Into Light";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/shadows-into-light-v15-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./fonts/shadows-into-light-v15-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/shadows-into-light-v15-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./fonts/shadows-into-light-v15-latin-regular.woff") format("woff"),
    /* Modern Browsers */
      url("./fonts/shadows-into-light-v15-latin-regular.ttf") format("truetype"),
    /* Safari, Android, iOS */
      url("./fonts/shadows-into-light-v15-latin-regular.svg#ShadowsIntoLight")
      format("svg"); /* Legacy iOS */
}

/* montserrat-regular - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/montserrat-v25-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./fonts/montserrat-v25-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/montserrat-v25-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("./fonts/montserrat-v25-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */ url("./fonts/montserrat-v25-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./fonts/montserrat-v25-latin-regular.svg#Montserrat") format("svg"); /* Legacy iOS */
}

/* frank-ruhl-libre-regular - latin */
@font-face {
  font-family: "Frank Ruhl Libre";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/frank-ruhl-libre-v12-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./fonts/frank-ruhl-libre-v12-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/frank-ruhl-libre-v12-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./fonts/frank-ruhl-libre-v12-latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("./fonts/frank-ruhl-libre-v12-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./fonts/frank-ruhl-libre-v12-latin-regular.svg#FrankRuhlLibre")
      format("svg"); /* Legacy iOS */
}
