/* NAV BUTTONS */
.navItemAngebote {
  position: relative;
  display: inline-block;
}

.navItemAngeboteContent {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  z-index: 999;
}

.navItemAngeboteContent a {
  color: black;
  text-decoration: none;
  display: block;
}

.navItemAngeboteContent a:hover {
  background-color: #ddd;
}

.navItemAngebote:hover .navItemAngeboteContent {
  display: block;
}

.navItemVerkaufen {
  position: relative;
  display: inline-block;
}

.navItemVerkaufenContent {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  z-index: 999;
}

.navItemVerkaufenContent a {
  color: black;
  text-decoration: none;
  display: block;
}

.navItemVerkaufenContent a:hover {
  background-color: #ddd;
}

.navItemVerkaufen:hover .navItemVerkaufenContent {
  display: block;
}

.navItemUnternehmen {
  position: relative;
  display: inline-block;
}

.navItemUnternehmenContent {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  z-index: 999;
}

.navItemUnternehmenContent a {
  color: black;
  text-decoration: none;
  display: block;
}

.navItemUnternehmenContent a:hover {
  background-color: #ddd;
}

.navItemUnternehmen:hover .navItemUnternehmenContent {
  display: block;
}
