.wrapper {
  margin: 8px auto;
  padding: 0 20px;
  position: relative;
}

.icon {
  position: absolute;
  z-index: 999;
}
.wrapper .center-line {
  position: absolute;
  height: 100%;
  width: 4px;
  background: #fff;
  left: 50%;
  top: 20px;
  transform: translateX(-50%);
}
.wrapper .row {
  display: flex;
}
.wrapper .row-1 {
  justify-content: flex-start;
}
.wrapper .row-2 {
  justify-content: flex-end;
}
.wrapper .row section {
  background: #fff;
  border-radius: 5px;
  width: calc(50% - 40px);
  padding: 30px;
  position: relative;
}
.wrapper .row section::before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  background: #fff;
  top: 28px;
  z-index: -1;
  transform: rotate(45deg);
}
.row-1 section::before {
  right: -7px;
}
.row-2 section::before {
  left: -7px;
}
.row section .icon,
.center-line .scroll-icon {
  position: absolute;
  background: #cebb8a;
  height: 45px;
  width: 45px;
  min-width: 45px;
  text-align: center;
  line-height: 45px;
  border-radius: 10%;
  font-size: 18px;
  color: white;
  border-color: white;
}
.center-line .scroll-icon {
  bottom: 0px;
  left: 50%;
  font-size: 25px;
  transform: translateX(-50%);
}
.center-line .scroll-icon:hover {
  background: #cebb8a;
}
.row-1 section .icon {
  top: 15px;
  right: -62px;
}
.row-2 section .icon {
  top: 15px;
  left: -62px;
}
.row section .details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
.row section .details .title {
  font-size: 22px;
  font-weight: 600;
  font-family: Montserrat;
  line-height: 1.5em;
  margin-bottom: 20px;
}
.row section p {
  /* margin: 10px 0 17px 0; */
  font-family: Montserrat;
  line-height: 1.5em;
}

@media (max-width: 900px) {
  .wrapper .center-line {
    left: 40px;
  }
  .wrapper .row {
    margin: 30px 0 3px 60px;
  }
  .wrapper .row section {
    width: 100%;
  }
  .row-1 section::before {
    left: -7px;
  }
  .row-1 section .icon {
    left: -60px;
  }
}

@media (max-width: 600px) {
  .wrapper {
    padding: 0;
  }
  .wrapper .center-line,
  .row section::before,
  .row section .icon {
    display: none;
  }
  .wrapper .row {
    margin: 10px 0;
  }
}
