.row {
  padding: 50px 0;
}

.seperator {
  margin-bottom: 30px;
  width: 35px;
  height: 3px;
  background: #777;
  border: none;
}

.item {
  position: relative;
  margin-bottom: 30px;
  min-height: 1px;
  float: left;
  width: 100%;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.item .item-in {
  background: #fff;
  padding: 40px;
  position: relative;
  height: 300px;
}

.item .item-in:hover::before {
  width: 100%;
}

.item .item-in::before {
  content: "";
  position: absolute;
  bottom: 0px;
  height: 3px;
  width: 0%;
  background: #cebb8a;
  right: 0px;
  -webkit-transition: width 0.8s;
  transition: width 0.8s;
}

.item h4 {
  text-transform: uppercase;
}
